import HeroImage from "../../images/hero.jpg";

const Hero = () => {
  return (
    <div
      className="bg-cover bg-center h-[70vh]"
      style={{ backgroundImage: `url(${HeroImage})` }}
    >
      <div className="flex flex-col items-start justify-center h-full">
        <h1 className="md:text-9xl md:p-10 p-5 text-4xl max-w-[800px] max-sm:text-center font-bold text-white">Welcome to our website</h1>
      </div>
    </div>
  );
};

export default Hero;
